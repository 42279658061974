@import './variables.scss';
@import './signin.scss';
@import './filters.scss';
@import './results.scss';
@import './top-bar.scss';
@import './snippet.scss';

@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700');

// Body ===============================
html, * {
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: $fontFamily;
  overflow: hidden;
  background-color: $neutralDark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  color:$white;
}

// Grid ===============================
.appWrapper {
  min-width: 100vw;
  min-height: 100vh;
  display:grid;
  grid-template-rows: 60px auto;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 6fr;
  }
}

main{
  display:grid;
  grid-template-columns: 1fr 4fr;
  grid-row-start: 2;
}

// Label, Input, Select ===============
label{
  font-size: $fontExtraSmall;
  letter-spacing: .5px;
  text-transform: uppercase;
  color:$yellowMid;
}

.input,
select{
  height:42px;
  border:0;
  border-radius: 4px;
  padding:0 $spacingRegular;
  font-family: 'Montserrat';
  width: 100%;
}

// Buttons ============================
.button{
  width:100%;
  border:0;
  border-radius: 4px;
  text-align: center;
  color: $neutralDarkest;
  text-transform: uppercase;
  font: 700 $fontSmall $fontFamily;
  cursor: pointer;
  max-width: 200px;
  padding:0 $spacingSmall;
  transition: .15s;
  height: 42px;
  box-shadow: $box-shadow;

  &:hover{
    background-color: $blueDark;
    color:$white;
  }
}

.button--rounded{
  border-radius: 20px;
  background-color: $tealMid;
}

.button--save {
  background-color: $yellowMid;
  margin-bottom: 40px;

  &:hover {
    background-color: $yellowDark;
    color: $neutralDarkest;
  }
}

.button-container{
  display:flex;
  margin:$spacingRegular 0 0;
  display: flex;
  align-items: center;
  justify-content: center;

  i{
    margin-right: $spacingExtraSmall;
  }

  .button{
    width:200px;
    border:2px solid;
    border-color:$blueMid;
    white-space: nowrap;

    &:first-of-type{
      background: $blueMid;
      color:$white;
    }

    &:last-of-type{
      margin-left:$spacingRegular;
      background-color: $white;
      color:$blueMid;
    }

    &:hover{
      background-color: $blueDark;
      border-color: $blueDark;
      color:$white;
    }
  }
}

.icon-container{
  margin:$spacingRegular 0 0;
}

// Icons ==============================
.icon{
  cursor: pointer;
  border-radius: 4px;
  width:40px;
  height: 40px;
  border:0;
  font-size: $fontLarge;
  margin-right: $spacingSmall;
  color:$white;
  box-shadow: $box-shadow;

  i{
    pointer-events: none;
  }
}

.icon--edit{
  background-color: $purpleMid;

  &:hover{
    background-color: #4D4064;
  }
}

.icon--delete{
  background-color: $pinkDark;

  &:hover{
    background-color: $pinkHover;
  }
}

.icon--save{
  background-color: $yellowDark;

  &:hover{
    background-color: #F1A541;
  }
}

// Scrollbar =========================
/* width */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: $neutralDarkest;
}
 
::-webkit-scrollbar-thumb {
  background: $tealLight; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: $tealMid; 
  cursor: pointer;
}