@import './variables.scss';

.top-bar{
  display:flex;
  background-color: $neutralDarkest;
  align-items: center;
  color:$white;

  .button--rounded{
    margin:0 $spacingRegular;
    justify-self:flex-end;
  }

  @media (min-width: 1024px) {
    display:grid;
    grid-column: 1 / span 2;
    grid-template-columns: 1fr 5fr 1fr;
  }
}

.top-bar__title{
  font-weight: 700;
  text-transform: uppercase;
  font-size: $fontLarge;
  margin-left: $spacingRegular;
  white-space: nowrap;
}

.search-bar{
  margin:0 $spacingRegular 0 $spacingExtraLarge;
  position: relative;
  width:100%;

  @media (min-width: 1024px) {
    width:auto;
    margin:0 $spacingExtraLarge;
  }
}

.search-bar__button{
  position: absolute;
  right:0;
  top:0;
  height: 42px;
  width: 42px;
  border-radius: 0 4px 4px 0;
  border:0;
  background-color: $neutralLightest;
  cursor: pointer;

  &:hover{
    background-color: $neutralLight;
  }
}