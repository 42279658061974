//Font ==========================
$fontFamily: 'Montserrat', 'Arial', 'Helvetica', sans-serif;
$fontExtraSmall: 10px;
$fontSmall: 14px;
$fontMedium: 18px;
$fontLarge: 20px;
$fontExtraLarge: 24px;
$fontHuge: 40px;

//Box shadow ==========================
$box-shadow:0 2px 6px rgba(0,0,0,.2);

// Colors =============================
//Neutral
$neutralDarkest: #202228;
$neutralDark: #2d394a;
$neutralLight: #97b1ba;
$neutralLightest: #e6e8e9;

//Blue
$blueDark: #005f7c;
$blueMid: #0f8797;
$blueLight: #6abec6;

//Teal
$tealDark: #049b89;
$tealMid: #93d2cc;
$tealLight: #bee3df;

//Purple
$purpleDark: #3c3159;
$purpleMid: #625180;
$purpleLight: #9588ae;
$purpleHover:#2D2442;

//Yellow
$yellowDark: #f4bb6e;
$yellowMid: #f9d27d;
$yellowLight: #f4e3c1;

//Pink
$pinkDark: #ca3a6e;
$pinkMid: #ec928e;
$pinkLight: #f2c8c7;
$pinkHover: #91274E;

//Orange
$orangeDark: #cb372a;
$orangeMid: #f77c30;
$orangeLight: #fba36c;

//Whites
$white: #ffffff;

//Gradients
$warmGradient: linear-gradient(to right, $yellowDark, #ec928e, $purpleDark);

// Spacing =============================
$spacingExtraSmall: 4px;
$spacingSmall: 8px;
$spacingRegular: 12px;
$spacingLarge: 16px;
$spacingExtraLarge: 24px;