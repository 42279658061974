@import './variables.scss';

.sign_in {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  color:$white;
  text-align: center;
  display: flex;
  flex-direction: column;

  img{
    margin:$spacingExtraLarge 0;
    max-width: 90vw;
  }

  .button{
    margin:0 auto;
    background-color: $pinkDark;
    color:$white;

    &:hover{
      background-color:$pinkHover ;
    }
  }
}
