@import './variables.scss';

.snippet{
  overflow: auto;
  height: calc(100vh - 60px);
  padding:0 $spacingRegular;

  .button-container{
    margin-right: $spacingRegular;
  }
}

.CodeMirror{
  border-radius: 4px;
  margin: 0 0 $spacingExtraSmall;
}

.actionable-buttons{
  display: flex;
  flex-wrap: wrap;
}

.snippet__header{
  margin:$spacingLarge 0 $spacingRegular;

  .input{
    margin:0 0 $spacingSmall;
  }
}

.snippet__name{
  font-size: $fontExtraLarge;
  font-weight: 700;
}

.snippet__description{
  font-size: $fontSmall;
  margin: 0 0 $spacingRegular;
}

.snippet__edit{
  display: none;
  position: relative;
}

.snippet__character-count{
  font-size: $fontExtraSmall;
  position: absolute;
  right: 0;
  top: calc(100% - 4px);
}

.snippet__filter-container{
  display:flex;
  margin: 0 0 $spacingRegular;
}

.snippet__filter {
  display: flex;
  flex-direction: column;

  label,
  select{
    display: none;
  }

  select{
    margin-top:2px;
  }
}

.snippet__filter-tag{
  font-size: $fontExtraSmall;
  padding: $spacingExtraSmall $spacingSmall;
  margin-right: $spacingSmall;
  text-transform: uppercase;
  letter-spacing: .5px;
  border-radius: 2px;
  border:1px solid $tealLight;
  color:$tealLight;
  display:inline-block;
}

.snippet__code{
  position: relative;

  .icon{
    position: absolute;
    right:$spacingRegular;
    bottom:$spacingRegular;
    opacity:0;
    transition: .15s;
    background-color: $blueMid;
    margin-right: 0;

    &:hover{
      background-color: $blueDark;
    }
  }

  &:hover{
    .icon{
      opacity:1;
    }
  }
}

.snippet__notes{
  background-color: $neutralDarkest;
  padding:$spacingLarge $spacingRegular $spacingSmall;
  color:$white;
  border-radius: 4px;
  margin:$spacingRegular 0;
}

.snippet__notes-header{
  display: inline-block;
  font-weight: 700;
  font-size: $fontMedium;

  &:after{
    content:'';
    width:60px;
    border-bottom: 1px solid $yellowMid;
    display:block;
    margin:$spacingExtraSmall 0 $spacingSmall;
  }
}

.snippet__notes-markdown-msg{
  display:none;
  font-size: $fontExtraSmall;
  font-weight: 400;
}

.snippet__notes-text{
  font-size: $fontSmall;
  margin:0 0 $spacingSmall;

  ol {
    padding-left: $spacingLarge; 
  }

  a{
    color:$yellowMid;
    text-transform: lowercase;
  }

  img{
    max-width: 100%;
  }
}

//Code only
.snippet--code-only{
  .snippet__notes{
    display:none;
  }
}

//Notes only
.snippet--notes-only{
  .snippet__code{
    display:none;
  }
}

//Editing
.snippet--editing{
  .snippet__edit,
  .snippet__notes-markdown-msg,
  .snippet__notes,
  .snippet__code{
    display: block;
  }

  .snippet__filter {
    margin-right: $spacingRegular;

    label,
    select{
      display: block;
    }
  }

  .snippet__notes-text,
  .snippet__filter-tag{
    display:none;
  }
}