@import './variables.scss';

.sidebar{
  grid-row-start: 2;
  overflow: auto;
  height: calc(100vh - 60px);
  padding:$spacingRegular $spacingSmall 0 $spacingRegular;
  display:none;

  @media (min-width: 1024px) {
    display:block;
  }
}

.filters__active{
  display:flex;
  flex-wrap: wrap;
  grid-gap: $spacingSmall;
}

.filter__active {
  font-size: $fontExtraSmall;
  padding: $spacingExtraSmall $spacingExtraLarge $spacingExtraSmall $spacingSmall;
  text-transform: uppercase;
  letter-spacing: .5px;
  border-radius: 4px;
  border: 1px solid $tealLight;
  color: $tealLight;
  position: relative;
  cursor: pointer;
  transition: .15s;

  &:after{
    content:'\00d7';
    position: absolute;
    font-size: $fontLarge;
    right:$spacingExtraSmall;
    top:50%;
    transform:translate(0,-50%);
  }

  &:hover{
    border-color: $pinkDark;
    color: $pinkDark;
  }
}

.filter__header{
  text-transform: uppercase;
  font-weight: 700;
  border-bottom: 1px solid $yellowMid;
  padding:$spacingRegular 0;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;

  &:after{
    content: '+';
    display: block;
    font-weight: 400;
    font-size: $fontLarge;
    line-height: 1;
    position: absolute;
    right: 0;
  }
}

.filter__group{
  margin:$spacingRegular 0;
  display:none;
}

.filter__name{
  padding-left: $spacingRegular;
  font-size: $fontSmall;

  &:hover{
    color:$yellowMid;
    cursor: pointer;
  }
}

.filter__name--active{
  font-weight: 700;
  color:$yellowDark;
}

.filter__category--open{
  .filter__header{
    &:after{
      content: '-';
      font-size: $fontHuge;
    }
  }

  .filter__group{
    display:block;
  }
}