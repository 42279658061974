@import './variables.scss';

.results__container{
  padding:0 $spacingRegular 0 $spacingExtraSmall;
  overflow: auto;
  height: calc(100vh - 60px);
  min-width: 348px;

  .button{
    font-size: $fontSmall;
  }

  @media (min-width: 1024px) {
    padding:0 $spacingExtraSmall 0 $spacingExtraLarge;
    margin-right: $spacingSmall;
  }
}

.result{
  border-radius: 4px;
  padding:$spacingLarge;
  margin:$spacingRegular 0;
  background: $white;
  color:$neutralDarkest;
  cursor: pointer;
}

.result--active{
  background-color: $tealLight;
}

.result__title {
  font-weight: 700;
  font-size: $fontMedium;
  color:$neutralDarkest;
}

.result__author{
  text-transform: uppercase;
  letter-spacing: .5px;
  font-size: $fontExtraSmall;
  margin:$spacingExtraSmall 0 0;
}

.result__description{
  margin:$spacingRegular 0 0;
  font-size: $fontSmall;
}